/* eslint-disable eqeqeq */
import { toast } from "react-toastify";
import { axiosData } from "../Config/Axios";

export   const PhoneFun = (e,user) => {
    if (user.is_verified == 1) {
      window.location.href = `tel:+${e?.host?.phone}`;
      const FormDat = new FormData();
      FormDat.append("car_id", e?.id);
      FormDat.append("type", "phone");
      axiosData
        .post(`/call-logs`, FormDat)
        .then((res) => {
          if ("") {
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      toast.error("YOU MUST BE VERIFIED TO USE THIS FEATURE.");
      return;
    }
  };
 export const WhatsappFun = (e,user) => {
    if (user.is_verified == 1) {
      window.open(
        `https://api.whatsapp.com/send?phone=${e?.host?.w_phone}`,
        "_blank"
      );
      const FormDat = new FormData();
      FormDat.append("car_id", e?.id);
      FormDat.append("type", "whatsapp");
      axiosData
        .post(`/call-logs`, FormDat)
        .then((res) => {
          console.log(res.data);
          if ("") {
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      toast.error("YOU MUST BE VERIFIED TO USE THIS FEATURE.");
      return;
    }
  };
/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/img-redundant-alt */
import { useEffect, useState } from "react";
import GalleryPicture from "./GalleryPicture";
// import Slider from "./Slider";
// import { axiosInstance } from "../../Config/AxiosInstance";
import { useParams } from "react-router-dom";
// import { FaHeart } from "react-icons/fa";
// import SingleMap from "./SingleMap";
// import { Rating } from "react-simple-star-rating";
import { axiosData } from "../../Config/Axios";
// import Ads from "../Ads/Ads";
import AboutAgent from "../AboutAgent/AboutAgent";
import Review from "../Review/Review";
import AllReview from "../Review/AllReview";
import ReportListing from "./ReportListing";
import { toast } from "react-toastify";
import {
  EmailShareButton,
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
// import Floor from "../Floor/Floor";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Vedio from "../Vedio/Vedio";

function SinglePro() {
  // const [rating, setRating] = useState(0);
  // console.log(rating);

  const [amenities, setamenities] = useState([]);

  // const handleRating = (rate) => {
  //   setRating(rate);
  // };
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [loader, setloader] = useState(false);

  const url = window.location.href;
  const quote = "Check out this amazing Car!";
  const [isFavourite, setIsFavourite] = useState(data?.isFavourite === 1);

  const GetData = () => {
    setloader(true);
    axiosData
      .get(`/car-user-get-by-id/${id}`)
      .then((res) => {
        if (res?.data?.status === true) {
          setData(res?.data?.car);
          console.log(res?.data?.car?.amenities === "");
          setloader(false);
          if (res?.data?.car?.amenities === "") {
            return;
          } else {
            const arrayOfStrings = JSON.parse(res?.data?.car?.amenities);

            const arrayOfObjects = arrayOfStrings.map((item) => {
              return { name: item };
            });
            setamenities(arrayOfObjects);
          }
        }
        setIsFavourite(res?.data?.car?.is_favourite === 1);
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
        setloader(true);
      });
  };

  useEffect(() => {
    GetData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const HandleFav = () => {
    const FormDat = new FormData();
    FormDat.append("car_id", id);
    axiosData
      .post(`/add-to-favourite`, FormDat)
      .then((res) => {
        console.log(res.data);
        if (res?.data?.message === "Unsave") {
          setIsFavourite(false);
          toast.success("Unfavorite");
        } else {
          toast.success("favorite");
          setIsFavourite(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <section id="property-single-gallery" className="property-single-gallery">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-12">
            <div className="property-single-gallery-info">
              <div className="property--info clearfix">
                <div className="pull-left">
                  <h5 className="property--title">
                    {loader ? (
                      <Skeleton />
                    ) : (
                      `${data?.year} ${data?.make?.name} ${data?.model?.name} ${data?.trim}`
                    )}
                  </h5>
                  <p className="property--location">
                    {loader ? <Skeleton /> : <i className="fa fa-map-marker" />}
                    {/* {data?.location} */}
                    {`${data?.city}, ${data?.district},${data?.region}, ${data?.country}`}
                  </p>
                </div>
                <div className="pull-right">
                  <span
                    className={
                      data?.carstype === "sale"
                        ? "property--status"
                        : "property--statusred"
                    }
                  >
                    For {data?.carstype === "sale" ? "Sale" : "Rent"}
                  </span>

                  <p className="property--price">
                    {data?.carstype === "sale"
                      ? `  GH₵ ${data?.price}`
                      : `  GH₵ ${data?.price_per_day}`}
                    {data?.carstype === "rent" && (
                      <sup style={{ fontSize: "20px" }}>/ Per Day</sup>
                    )}
                  </p>
                </div>
              </div>
              {/* .property-info end */}
              <div className="property--meta clearfix">
                <div className="pull-left">
                  <ul
                    className="list-unstyled list-inline mb-0"
                    style={{ display: "flex" }}
                  >
                    <li style={{ display: "flex" }}>
                      Property ID:
                      <span className="value">{data?.unique_no}</span>
                    </li>
                    <li style={{ display: "flex" }}>
                      Add to favorites:
                      <span className="value" onClick={HandleFav}>
                        {" "}
                        {isFavourite ? (
                          <i
                            className="fa fa-heart"
                            style={{ marginLeft: "10px", cursor: "pointer" }}
                          />
                        ) : (
                          <i
                            className="fa fa-heart-o"
                            style={{ marginLeft: "10px", cursor: "pointer" }}
                          />
                        )}
                        {/* <div style={{marginLeft:"10px"}}>
                          
                        <FaHeart />
                        </div> */}
                      </span>
                    </li>
                  </ul>
                </div>
                <div className="pull-right">
                  <div className="property--meta-share">
                    <span className="share--title">share</span>
                    <a href="#" className="twitter">
                      <TwitterShareButton url={url} quote={quote}>
                        <i className="fa fa-twitter" />
                      </TwitterShareButton>
                    </a>
                    <a href="#" className="facebook">
                      <FacebookShareButton url={url} quote={quote}>
                        <i className="fa fa-facebook" />
                      </FacebookShareButton>
                    </a>
                    <a href="#" className="google-plus">
                      <WhatsappShareButton url={url} quote={quote}>
                        <i className="fa fa-whatsapp" />
                      </WhatsappShareButton>
                    </a>
                    <a href="#" className="pinterest">
                      <EmailShareButton url={url} quote={quote}>
                        <i class="fa fa-envelope"></i>
                      </EmailShareButton>
                    </a>
                  </div>
                  {/* .property-meta-share end */}
                </div>
              </div>
              {/* .property-info end */}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-8">
            <div className="property-single-carousel inner-box">
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12">
                  <div className="heading">
                    <h2 className="heading--title">Gallery</h2>
                  </div>
                </div>
                <GalleryPicture data={data} loader={loader} />
              </div>
            </div>
            <div className="property-single-desc inner-box">
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12">
                  <div className="heading">
                    <h2 className="heading--title">Description</h2>
                  </div>
                </div>
                {/* <div className="col-xs-6 col-sm-4 col-md-4">
                  <div className="feature-panel">
                    <div className="feature--img">
                      <img
                        src="/assets/images/area-icon.png"
                        alt="icon"
                      />
                    </div>
                    <div className="feature--content">
                      <h5>Area: 400</h5>
                      <p>{data?.size} sq ft</p>
                    </div>
                  </div>
                </div> */}
                <div className="col-xs-6 col-sm-4 col-md-4">
                  <div className="feature-panel">
                    <div className="feature--img">
                      <img
                        src="https://static-assets.cargurus.com/images/site-cars/vdp-icons/icon-total-mileage_40eabb5c87e87bc447dfc14f55bbd575bfdb10a978c61a1fb1fad87148f15721.svg"
                        alt="icon"
                        className="nmn"
                      />
                    </div>
                    <div className="feature--content">
                      <h5>
                        Mileage: {data?.mileage_type + " " + data?.mileage}{" "}
                      </h5>
                      {/* <p>{data?.no_of_bedroom} `</p> */}
                    </div>
                  </div>
                </div>
                {/* <div className="col-xs-6 col-sm-4 col-md-4">
                  <div className="feature-panel">
                    <div className="feature--img">
                      <img
                        src="/assets/images/garage-icon.png"
                        alt="icon"
                      />
                    </div>
                    <div className="feature--content">
                      <h5>Garage: 1</h5>
                      <p>{data?.no_of_bathroom} '</p>
                    </div>
                  </div>
                </div> */}
                <div className="col-xs-6 col-sm-4 col-md-4">
                  <div className="feature-panel">
                    <div className="feature--img">
                      <img
                        src="https://static-assets.cargurus.com/images/site-cars/vdp-icons/icon-exterior-color_96d64c303267dc97432a987c99f59c5c07c5f4f97d71790738e3c0b72cb534c9.svg"
                        alt="icon"
                        className="nmn"
                      />
                    </div>
                    <div className="feature--content">
                      <h5>Exterior color: {data?.exterior?.name} </h5>
                      {/* <p>{data?.no_of_room} `</p> */}
                    </div>
                  </div>
                </div>
                <div className="col-xs-6 col-sm-4 col-md-4">
                  <div className="feature-panel">
                    <div className="feature--img">
                      <img
                        src="https://static-assets.cargurus.com/images/site-cars/vdp-icons/icon-interior-color_4cf9c7f3fdb85e34a8120d38cc38aa9f5c5ed930d542e09979003b0bb79e2483.svg"
                        alt="icon"
                        className="nmn"
                      />
                    </div>
                    <div className="feature--content">
                      <h5>Interior color: {data?.interior?.name} </h5>
                    </div>
                  </div>
                </div>
                <div className="col-xs-6 col-sm-4 col-md-4">
                  <div className="feature-panel">
                    <div className="feature--img">
                      <img
                        src="https://static-assets.cargurus.com/images/site-cars/vdp-icons/icon-fuel-type_670bab1e0b56aac2d6635a5cb353099061396d70dca9c904ed4d5cf16c704c58.svg"
                        alt="icon"
                        className="nmn"
                      />
                    </div>
                    <div className="feature--content">
                      <h5>Fuel Type : {data?.fuel_type} </h5>
                    </div>
                  </div>
                </div>
                <div className="col-xs-6 col-sm-4 col-md-4">
                  <div className="feature-panel">
                    <div className="feature--img">
                      <img
                        src="https://static-assets.cargurus.com/images/site-cars/vdp-icons/icon-engine_340b2adb88d16921896d3f1abbcea040c44f353cd3121297cbbb460336dcc3bf.svg"
                        alt="icon"
                        className="nmn"
                      />
                    </div>
                    <div className="feature--content">
                      <h5>Engine Size : {data?.engine_size?.name} </h5>
                    </div>
                  </div>
                </div>
                <div className="col-xs-6 col-sm-4 col-md-4">
                  <div className="feature-panel">
                    <div className="feature--img">
                      <img
                        src="https://static-assets.cargurus.com/images/site-cars/vdp-icons/icon-transmission_6d95c32965c640395c6e92981701da6de13a781ab863ce0c190e8a84fb2e5754.svg"
                        alt="icon"
                        className="nmn"
                      />
                    </div>
                    <div className="feature--content">
                      <h5>Transmission : {data?.transmission} </h5>
                    </div>
                  </div>
                </div>
                {/* <div className="col-xs-6 col-sm-4 col-md-4">
                  <div className="feature-panel">
                    <div className="feature--img">
                      <img
                        src="/assets/images/property-single/features/5.png"
                        alt="icon"
                      />
                    </div>
                    <div className="feature--content">
                      <h5>Floors:</h5>
                      <p>3 Floors</p>
                    </div>
                  </div>
                </div> */}
                {/* feature-panel end */}
                {/* feature-panel #6 */}
                {/* <div className="col-xs-6 col-sm-4 col-md-4">
                  <div className="feature-panel">
                    <div className="feature--img">
                      <img
                        src="/assets/images/property-single/features/6.png"
                        alt="icon"
                      />
                    </div>
                    <div className="feature--content">
                      <h5>Garage:</h5>
                      <p>2 Garages</p>
                    </div>
                  </div>
                </div> */}
                {/* feature-panel end */}
                <div className="col-xs-12 col-sm-12 col-md-12">
                  <div className="property--details">
                    <p>{data?.description}</p>
                  </div>
                </div>
              </div>
              {/* .row end */}
            </div>
            <div className="property-single-desc inner-box">
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12">
                  <div className="heading">
                    <h2 className="heading--title">OverView</h2>
                  </div>
                </div>
                {/* <div className="col-xs-6 col-sm-4 col-md-4">
                  <div className="feature-panel">
                    <div className="feature--img">
                      <img
                        src="/assets/images/area-icon.png"
                        alt="icon"
                      />
                    </div>
                    <div className="feature--content">
                      <h5>Area: 400</h5>
                      <p>{data?.size} sq ft</p>
                    </div>
                  </div>
                </div> */}
                <div className="col-xs-6 col-sm-4 col-md-4">
                  <div className="feature-panel">
                    <div className="feature--content">
                      <h5>
                        Mileage: {data?.mileage_type + " " + data?.mileage}{" "}
                      </h5>
                      {/* <p>{data?.no_of_bedroom} `</p> */}
                    </div>
                  </div>
                </div>
                {/* <div className="col-xs-6 col-sm-4 col-md-4">
                  <div className="feature-panel">
                    <div className="feature--img">
                      <img
                        src="/assets/images/garage-icon.png"
                        alt="icon"
                      />
                    </div>
                    <div className="feature--content">
                      <h5>Garage: 1</h5>
                      <p>{data?.no_of_bathroom} '</p>
                    </div>
                  </div>
                </div> */}
                <div className="col-xs-6 col-sm-4 col-md-4">
                  <div className="feature-panel">
                    <div className="feature--content">
                      <h5>Exterior color: {data?.exterior?.name} </h5>
                      {/* <p>{data?.no_of_room} `</p> */}
                    </div>
                  </div>
                </div>
                <div className="col-xs-6 col-sm-4 col-md-4">
                  <div className="feature-panel">
                    <div className="feature--content">
                      <h5>Interior color: {data?.interior?.name} </h5>
                    </div>
                  </div>
                </div>
                <div className="col-xs-6 col-sm-4 col-md-4">
                  <div className="feature-panel">
                    <div className="feature--img"></div>
                    <div className="feature--content">
                      <h5>Fuel Type : {data?.fuel_type} </h5>
                    </div>
                  </div>
                </div>
                <div className="col-xs-6 col-sm-4 col-md-4">
                  <div className="feature-panel">
                    <div className="feature--img"></div>
                    <div className="feature--content">
                      <h5>Engine Size : {data?.engine_size?.name} </h5>
                    </div>
                  </div>
                </div>
                <div className="col-xs-6 col-sm-4 col-md-4">
                  <div className="feature-panel">
                    <div className="feature--img"></div>
                    <div className="feature--content">
                      <h5>Year : {data?.year} </h5>
                    </div>
                  </div>
                </div>
                <div className="col-xs-6 col-sm-4 col-md-4">
                  <div className="feature-panel">
                    <div className="feature--img"></div>
                    <div className="feature--content">
                      <h5>Trim: {data?.trim} </h5>
                    </div>
                  </div>
                </div>
                <div className="col-xs-6 col-sm-4 col-md-4">
                  <div className="feature-panel">
                    <div className="feature--img"></div>
                    <div className="feature--content">
                      <h5>Interior: {data?.interior?.name} </h5>
                    </div>
                  </div>
                </div>
                <div className="col-xs-6 col-sm-4 col-md-4">
                  <div className="feature-panel">
                    <div className="feature--img"></div>
                    <div className="feature--content">
                      <h5>Exterior: {data?.exterior?.name} </h5>
                    </div>
                  </div>
                </div>
                <div className="col-xs-6 col-sm-4 col-md-4">
                  <div className="feature-panel">
                    <div className="feature--img"></div>
                    <div className="feature--content">
                      <h5>Door: {data?.door?.name} </h5>
                    </div>
                  </div>
                </div>
                <div className="col-xs-6 col-sm-4 col-md-4">
                  <div className="feature-panel">
                    <div className="feature--img"></div>
                    <div className="feature--content">
                      <h5>VIN: {data?.vin} </h5>
                    </div>
                  </div>
                </div>
                <div className="col-xs-6 col-sm-4 col-md-4">
                  <div className="feature-panel">
                    <div className="feature--img"></div>
                    <div className="feature--content">
                      <h5>Body Type: {data?.body_type?.name} </h5>
                    </div>
                  </div>
                </div>
                <div className="col-xs-6 col-sm-4 col-md-4">
                  <div className="feature-panel">
                    <div className="feature--img"></div>
                    <div className="feature--content">
                      <h5>Condition: {data?.condition?.name} </h5>
                    </div>
                  </div>
                </div>
                <div className="col-xs-6 col-sm-4 col-md-4">
                  <div className="feature-panel">
                    <div className="feature--img"></div>
                    <div className="feature--content">
                      <h5>Transmission: {data?.transmission} </h5>
                    </div>
                  </div>
                </div>
                {/* <div className="col-xs-6 col-sm-4 col-md-4">
                  <div className="feature-panel">
                    <div className="feature--img">
                      <img
                        src="/assets/images/property-single/features/5.png"
                        alt="icon"
                      />
                    </div>
                    <div className="feature--content">
                      <h5>Floors:</h5>
                      <p>3 Floors</p>
                    </div>
                  </div>
                </div> */}
                {/* feature-panel end */}
                {/* feature-panel #6 */}
                {/* <div className="col-xs-6 col-sm-4 col-md-4">
                  <div className="feature-panel">
                    <div className="feature--img">
                      <img
                        src="/assets/images/property-single/features/6.png"
                        alt="icon"
                      />
                    </div>
                    <div className="feature--content">
                      <h5>Garage:</h5>
                      <p>2 Garages</p>
                    </div>
                  </div>
                </div> */}
                {/* feature-panel end */}
              </div>
              {/* .row end */}
            </div>

            <div className="property-single-features inner-box">
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12">
                  <div className="heading">
                    <h2 className="heading--title">
                      {amenities.length !== 0
                        ? "Features"
                        : "Features Not Found"}
                    </h2>
                  </div>
                </div>
                {amenities?.map((e) => {
                  return (
                    <>
                      <div className="col-xs-6 col-sm-4 col-md-4">
                        <div className="feature-item">
                          <p>{e?.name}</p>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
            <div className="property-single-location inner-box">
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12">
                  <div className="heading">
                    <h2 className="heading--title">Location</h2>
                  </div>
                </div>

                <div className="col-xs-12 col-sm-12 col-md-12">
                  <ul className="list-unstyled mb-20">
                    {/* <li>
                      <span>Location:</span>
                      {data?.location}
                    </li> */}
                    <li>
                      <span>City:</span>
                      {data?.city}
                    </li>
                    <li>
                      <span>Region:</span>
                      {data?.region}
                    </li>
                    <li>
                      <span>district:</span>
                      {data?.district}
                    </li>
                    <li>
                      <span>Country:</span>
                      {data?.country}
                    </li>
                  </ul>
                </div>
                {/* .col-md-12 end */}
                {/* <SingleMap data={data} /> */}
                {/* .col-md-12 end */}
              </div>
              {/* <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12">
                  <div className="heading">
                    <h2 className="heading--title">Time Periods</h2>
                  </div>
                </div>

                <div className="col-xs-12 col-sm-12 col-md-12">
                  <ul className="list-unstyled mb-20">
                    <li>
                      <span>Rent Period:</span>
                      {data?.rent_period}
                    </li>
                    <li>
                      <span>Availability:</span>
                      {data?.availability}
                    </li>
                  </ul>
                </div>
                
              </div> */}
              {/* .row end */}
            </div>
            <div
              className="col-xs-12 col-sm-12 col-md-12"
              style={{ marginLeft: "20px" }}
            >
              {/* <div className="heading">
                <h2 className="heading--title" style={{ fontSize: "18px" }}>
                  Lease Term
                </h2>
              </div> */}
              {/* <div className="property--details">
                <p style={{ textAlign: "justify" }}>{data?.lease_term}</p>
              </div> */}
            </div>
            {/* .property-single-location end */}
            {/* <Floor/> */}

            {/* .property-single-design end */}

            {/* .property-single-video end */}
            {data?.is_link_publish != 0 && <Vedio data={data} />}
            <AllReview id={id} Data={data} />
            <Review />
          </div>
          <div className="col-xs-12 col-sm-12 col-md-4">
            <AboutAgent data={data} id={data?.host?.id} />

            <ReportListing id={id} />

            {/* <Slider data={data} /> */}

            {/* <div className="widget widget-mortgage-calculator">
              <div className="widget--title">
                <h5>Mortage Calculator</h5>
              </div>
              <div className="widget--content">
                <form className="mb-0">
                  <div className="form-group">
                    <label htmlFor="sale-price">Sale Price</label>
                    <input
                      type="text"
                      className="form-control"
                      name="sale-price"
                      id="sale-price"
                      placeholder="GH₵ "
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="down-payment">Down Payment</label>
                    <input
                      type="text"
                      className="form-control"
                      name="down-payment"
                      id="down-payment"
                      placeholder="GH₵ "
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="term">Term</label>
                    <input
                      type="text"
                      className="form-control"
                      name="term"
                      id="term"
                      placeholder="years"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="interest-rate">Interest Rate</label>
                    <input
                      type="text"
                      className="form-control"
                      name="interest-rate"
                      id="interest-rate"
                      placeholder="%"
                    />
                  </div>
                  <input
                    type="submit"
                    defaultValue="Calculate"
                    name="submit"
                    className="btn btn--primary btn--block"
                  />
                </form>
              </div>
            </div> */}
            {/* <Ads /> */}
          </div>
        </div>
      </div>
    </section>
  );
}

export default SinglePro;

/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
// src/components/Detail.js

import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { axiosData } from "../../Config/Axios";
// import { toast } from "react-toastify";
import LoaderCard from "../Loader/LoaderCard";
import { AuthContext } from "../../Config/Context/Context";
import { HandleFav } from "../../Helper/AddToFavServices";
import { PhoneFun, WhatsappFun } from "../../Helper/CallingServices";

function HouseCom({ states, cities }) {
  let { filtersRent } = useContext(AuthContext);
  // console.log(filtersRent);
  // const filter = states?.filter(
  //   (e) => e.value === Number(filtersRent.selectedState)
  // );
  // const filter1 = cities?.filter(
  //   (e) => e.value === Number(filtersRent.selectedCity)
  // );

  // const { state } = useLocation();
  const [, setHeaderColor] = useState("transparent");
  const [grid, setgrid] = useState("");
  const user = JSON.parse(localStorage.getItem("user"));
  // const token = localStorage.getItem("Token");

  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(false);

  const navigate = useNavigate();

  const GetData = () => {
    setLoader(true);

    const queryParams = [];

    if (filtersRent.cityTown) queryParams.push(`city=${filtersRent.cityTown}`);
    if (filtersRent.price_per_day)
      queryParams.push(`price_per_day=${filtersRent.price_per_day}`);
    if (filtersRent.years) queryParams.push(`year=${filtersRent.years}`);
    if (filtersRent.rentPeriod)
      queryParams.push(`rent_period=${filtersRent.rentPeriod}`);
    if (filtersRent.availability)
      queryParams.push(`availability=${filtersRent.availability}`);
    if (filtersRent.beds) queryParams.push(`bed=${filtersRent.beds}`);
    if (filtersRent.baths) queryParams.push(`bath=${filtersRent.baths}`);
    if (filtersRent.sorts) queryParams.push(`sort=${filtersRent.sorts}`);
    if (filtersRent.fuelType)
      queryParams.push(`fuel_type=${filtersRent.fuelType}`);
    if (filtersRent.engineSize)
      queryParams.push(`engine_size=${filtersRent.engineSize}`);
    if (filtersRent.doors) queryParams.push(`doors=${filtersRent.door}`);
    if (filtersRent.transmissions) queryParams.push(`transmission=${filtersRent.transmissions}`);

    if (filtersRent.selectedMake) {
      // const stateLabel = filter[0]?.label;
      queryParams.push(`make=${filtersRent?.selectedMake}`);
    }
    if (filtersRent.selectedtrim) {
      // const stateLabel = filter[0]?.label;
      queryParams.push(`trim=${filtersRent?.selectedtrim}`);
    }
    if (filtersRent.selectedModel) {
      // const stateLabel = filter[0]?.label;
      queryParams.push(`model=${filtersRent?.selectedModel}`);
    }
    // const type = filtersRent.type ? filtersRent.type : "sale";
    // const sort = filtersRent.sorts ? filtersRent.sorts : "asc";
    // const cityLabel = filtersRent.selectedCity ? filter1[0]?.label : "";

    // queryParams.push(`region=${stateLabel}`);
    // queryParams.push(`carstype=${type}`);
    // queryParams.push(`sort=${sort}`);
    // if (cityLabel) queryParams.push(`district=${cityLabel}`);

    const url = `/car-user?${queryParams.join("&")}`;

    axiosData
      .get(url)
      .then((res) => {
        if (res?.data?.status === true) {
          const Data = res?.data?.data;
          const filter = Data?.filter((e) => e?.carstype === "rent");
          setData(filter);
        }
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  };

  useEffect(() => {
    GetData();

    window.scrollTo(0, 0);
    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      if (currentScrollY < 1700) {
        setHeaderColor("blue");
      } else {
        setHeaderColor("transparent");
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersRent]);

  return (
    <>
      <div
        className="properties-split pt-0 pb-0 bg-white"
        id="properties-split"
      >
        <div className="container-fluid">
          <div className="">
            <div className="col-xs-12 col-sm-12 col-md-12">
              <div
                className={`row pt-45 properties-list  ${
                  grid === "grid" ? "listgrid" : "listgrid1"
                }`}
              >
                <div className="col-xs-12 col-sm-12 col-md-12">
                  <div className="properties-filter clearfix">
                    <div className="select--box pull-left">
                      <label>{data?.length} Results Found</label>
                    </div>
                    <div className={`view--type pull-right faiz`}>
                      <a
                        className={grid === "list" ? "active" : ""}
                        href="#"
                        id="switch-list"
                        onClick={() => setgrid("list")}
                      >
                        <i className="fa fa-th-list" />
                      </a>
                      <a
                        className={grid === "grid" ? "active" : ""}
                        href="#"
                        id="switch-grid"
                        onClick={() => setgrid("grid")}
                      >
                        <i className="fa fa-th-large" />
                      </a>
                    </div>
                  </div>
                </div>

                <div
                  className={`properties properties-grid ${
                    grid === "list" ? "newhello1" : "newhello"
                  }`}
                >
                  {loader ? (
                    <LoaderCard />
                  ) : (
                    data.map((e) => (
                      <div key={e.id} className="col-xs-12 col-sm-12 col-md-12">
                        <div
                          className={`property-item ${
                            grid === "list" ? "listby" : ""
                          }`}
                        >
                          <div className="property--img">
                            <img
                              alt="property image"
                              onClick={() =>
                                navigate(`/en-gh/singlecar/${e.id}`)
                              }
                              className={`img-responsive ${
                                grid === "list" ? "h-274" : "h-200"
                              }`}
                              src={e.car_images?.[0]?.image}
                            />
                                                       <span className={e?.carstype ==='sale' ? 'property--status':'property--statusred'}>
For Rent</span>
                          </div>
                          <div className="property--content">
                            <div className="property--info">
                              <h5 className="property--title">
                              <a href="#">{`${e?.year} ${e?.make?.name} ${e?.model?.name} ${e?.trim}`}</a>
                                
                                <span
                                  className="value"
                                  onClick={() =>
                                    HandleFav(e?.id, user, GetData)
                                  }
                                  style={{ float: "right" }}
                                >
                                  {e?.is_favourite === 1 ? (
                                    <i
                                      className="fa fa-heart"
                                      style={{
                                        marginLeft: "10px",
                                        cursor: "pointer",
                                      }}
                                    />
                                  ) : (
                                    <i
                                      className="fa fa-heart-o"
                                      style={{
                                        marginLeft: "10px",
                                        cursor: "pointer",
                                      }}
                                    />
                                  )}
                                </span>
                              </h5>
                              <p className="property--location">
                                <i className="fa fa-map-marker" />
                                {e?.city} ,{e?.district}, {e?.region}
                                <span style={{ float: "right" }}>
                                  {e?.created_at}
                                </span>
                              </p>
                              <div className="flex1">
                                <p
                                  className="property--price "
                                  style={{ display: "flex" }}
                                >
                                  GH₵{e?.price_per_day}
                                  <sup>/Day</sup>
                                </p>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "end",
                                    width: "100%",
                                  }}
                                >
                                  <button
                                    className="bg-black p-xs border-none button-detail"
                                    onClick={() => PhoneFun(e, user)}
                                  >
                                    <i
                                      className="fa fa-phone"
                                      style={{ color: "white" }}
                                    />
                                  </button>
                                  <button
                                    className="btn--primaryw  p-xs ml-xs border-none button-detail"
                                    onClick={() => WhatsappFun(e, user)}
                                  >
                                    <i
                                      className="fa fa-whatsapp"
                                      style={{ color: "white" }}
                                    />
                                  </button>
                                  {e?.host?.is_verified == "1" && (
                                    <img
                                      style={{
                                        width: "50px",
                                        marginTop: "-3px",
                                      }}
                                      src="/assets/images/verify.webp"
                                    />
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="property--features">
                              {/* {/* <p>{e?.description.slice(0, 83)}</p> */}
                              <div className="flex1">
                              <div className="">
                                  <img
                                    className="mtt-10"
                                    src="https://static-assets.cargurus.com/images/site-cars/vdp-icons/icon-total-mileage_40eabb5c87e87bc447dfc14f55bbd575bfdb10a978c61a1fb1fad87148f15721.svg"
                                    alt=""
                                  />
                                  <span className="ml-nine bold font-20 text-black ">
                                    {e?.mileage_type +' '+e?.mileage}
                                  </span>
                                </div>
                                <div className="">
                                  <img
                                    className="mtt-10"
                                    src="https://static-assets.cargurus.com/images/site-cars/vdp-icons/icon-transmission_6d95c32965c640395c6e92981701da6de13a781ab863ce0c190e8a84fb2e5754.svg"
                                    alt=""
                                  />
                                  <span className="ml-nine bold font-20 text-black ">
                                    {e?.transmission }
                                  </span>
                                </div>
                                <div className="">
                                  <img
                                    className="mtt-10"
                                    src="https://static-assets.cargurus.com/images/site-cars/vdp-icons/icon-engine_340b2adb88d16921896d3f1abbcea040c44f353cd3121297cbbb460336dcc3bf.svg"
                                    alt=""
                                  />
                                  <span className="ml-nine bold font-20 text-black ">
                                    {e?.engine_size?.name}
                                  </span>
                                </div>
                                {/* <div className="">
                               
                                  <img
                                    className="mtt-10"
                                    src="https://static-assets.cargurus.com/images/site-cars/vdp-icons/icon-fuel-type_670bab1e0b56aac2d6635a5cb353099061396d70dca9c904ed4d5cf16c704c58.svg"
                                    alt=""
                                  />
                                  <span className="ml-nine bold font-20 text-black ">
                                    {e?.fuel_type}
                                  </span>
                                </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HouseCom;

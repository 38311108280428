import React, { useEffect, useRef, useState } from "react";

import Hero from "../Component/Hero";
// import Latest from '../Component/Latest';
// import PropertyBy from "../Component/PropertyBy";
import SimpleStep from "../Component/SimpleStep";
// import TrustedAgent from "../Component/TrustedAgent";
// import Findus from "../Component/Findus";
import Layout from "../Component/Layout/Layout";
// import PropertyByone from "../Component/PropertyByone";
// import PropertyBytwo from "../Component/PropertyBytwo";
// import ScrollToTop from "../Component/Scroltotop";
// import Accra from "../Component/Front/Front";
// import Tema from "../Component/Front/Tema";
// import Ashaiman from "../Component/Front/Ashaiman";
// import Sakumono from "../Component/Front/Sakumono";
// import Capitals from "../Component/Front/Capitals";
// import DCapitals from "../Component/Front/DCapitals";
// import Popular from "../Component/Front/PopularCities";
import { axiosData } from "../Config/Axios";
import Whatsapp from "../Component/WhatsApp";
import CookieConsent from "../Component/Cokkie/Cokkies";
import Feature from "../Component/Feature/Feature";

import BannerHome from "../Component/Ads/BannerHome";

// import { useLocation } from "react-router-dom";

function Home() {
  const [image, setImage] = useState([]);
  const popup = localStorage.getItem("adSeen");
  const popup_one = localStorage.getItem("notSeen");

  const GetData = () => {
    axiosData
      .get(`image-setting`)
      .then((res) => {
        if (res?.data?.status === true) {
          setImage(res?.data?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  let a = 1;
  if (image?.[0]?.status_one && !popup) {
    if (!image?.[0]?.status_seven && popup_one) {
      a = 1;
      setTimeout(() => {
        localStorage.clear();
      }, 5000);
    } else {
      a = 0;
    }
  }
  if (image?.[0]?.status_seven && popup) {
    a = 0;
    setTimeout(() => {
      localStorage.clear();
    }, 5000);
  }
  // let a =
  //   (image?.[0]?.status_one) || ()
  //     ? 0
  //     : 1;

  useEffect(() => {
    GetData();
    if (a === 0) {
      loginModal();
      a++;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [a]);
  const isLogin = useRef();
  const loginModal = () => {
    isLogin?.current?.click();
  };

  return (
    <div>
      <Layout >
        
        <Hero />
        <CookieConsent />
        <div style={{ background: "#f2f7ff" }}>
          <Feature image={image} />
        </div>
        {/* <div style={{ background: "#f2f7ff" }}>
          <PropertyBy image={image} />
        </div> */}
        {/* <div style={{ background: "#f2f7ff", marginTop: "20px" }}>
          <Accra image={image} />
        </div> */}
        {/* <div style={{ background: "#f2f7ff", marginTop: "20px" }}>
          <Tema image={image} />
        </div> */}
        {/* <div style={{ background: "#f2f7ff", marginTop: "20px" }}>
          <Ashaiman image={image} />
        </div> */}
        {/* <div style={{ background: "#f2f7ff", marginTop: "20px" }}>
          <Sakumono image={image} />
        </div> */}
        {/* <div style={{ background: "#f2f7ff", marginTop: "20px" }}>
          <Capitals image={image} />
        </div> */}
        {/* <div style={{ background: "#f2f7ff", marginTop: "20px" }}>
          <DCapitals image={image} />
        </div> */}
        {/* <div style={{ background: "#f2f7ff", marginTop: "20px" }}>
          <Popular image={image} />
        </div> */}
        {/* <div style={{ background: "#f2f7ff", marginTop: "20px" }}>
          <PropertyByone image={image} />
        </div> */}
        {/* <div style={{ background: "#f2f7ff", marginTop: "20px" }}>
          <PropertyBytwo image={image} />
        </div> */}
        <SimpleStep />
        {/* <TrustedAgent /> */}
        {/* <Findus /> */}
        <Whatsapp />

        <button
          ref={isLogin}
          type="button"
          className="btn btn-primary"
          data-toggle="modal"
          data-target="#exampleModal"
          style={{ width: "0px", height: "0px" }}
        ></button>
        <BannerHome
          id={"exampleModal"}
          image={popup ? image?.[0]?.image_seven : image?.[0]?.image_one}
          link={popup ? image?.[0]?.link_seven : image?.[0]?.link_one}
        />
      </Layout>
    </div>
  );
}

export default Home;

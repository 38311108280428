/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Modal from "./Modal";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { axiosData } from "../Config/Axios";
import { axiosInstance } from "../Config/AxiosInstance";

function Header() {
  const scrolltop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const [condition, setCondition] = useState({
    login: true,
    signup: false,
    forget: false,
    reset: false,
  });

  const isLogin = useRef();
  // const loginModal = () => {
  //   isLogin?.current?.click();
  // };

  const [headerColor, setHeaderColor] = useState("transparent");
  const token = localStorage.getItem("Token");
  const [loginCalled, setLoginCalled] = useState(false);

  const navigate = useNavigate();

  const HandleLogout = () => {
    localStorage.clear();
    toast.success("Logout Successfully");
    axiosData
      .get(`image-setting`)
      .then((res) => {
        if (res?.data?.data?.[0]?.status_seven === 1) {
          localStorage.setItem("adSeen", true);
        } else {
          localStorage.setItem("notSeen", true);
        }
        window.location.reload();
        navigate("/en-gh");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("userlogin");

  const Login = () => {
    const formData = new FormData();
    formData.append("host_id", id);
    formData.append("role", "user");

    axiosInstance
      .post("/login", formData)
      .then((res) => {
        if (res?.data?.data) {
          localStorage.setItem("Token", res?.data?.data?.accessToken);
          localStorage.setItem("user", JSON.stringify(res?.data?.data?.user));
          setCondition({
            login: false,
            signup: false,
            forget: false,
            reset: false,
          });
          localStorage.removeItem("adSeen");
        }
      })
      .catch((err) => {
        console.error("Error in login", err);
      });
  };

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      setHeaderColor(currentScrollY > 30 ? "blue" : "transparent");
    };

    window.addEventListener("scroll", handleScroll);

    if (id && !loginCalled) {
      Login();
      setLoginCalled(true); // Mark that login has been called
    }

    return () => window.removeEventListener("scroll", handleScroll);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, loginCalled]); // Add loginCalled to dependency array

  return (
    <header
      id="navbar-spy"
      style={{ zIndex: 3 }}
      className="header header-1 header-transparent header-fixed"
    >
    <nav
        id="primary-menu"
        className={` ${
          headerColor === "transparent"
            ? "navbar navbar-fixed-top affix-top bg-faiz"
            : "navbar navbar-fixed-top affix"
        } `}
      >
        <div className="container-fluid">
          <div className="navbar-header">
            <button
              type="button"
              className="navbar-toggle collapsed"
              data-toggle="collapse"
              data-target="#navbar-collapse-1"
              aria-expanded="false"
            >
              <span className="sr-only">Toggle Navigation</span>
              <span className="icon-bar" />
              <span className="icon-bar" />
              <span className="icon-bar" />
            </button>
            <Link to={"/en-gh"} className="logo">
              <img
                className={
                  headerColor === "transparent" ? "logo-light" : "logo-dark"
                }
                style={{ width: "140px", height: "35px" }}
                src={
                  headerColor === "transparent"
                    ? "/assets/images/logo-white.png"
                    : "/assets/images/logo-dark2.png"
                }
                alt="Land Logo"
              />
            </Link>
          </div>
          <div
            className={`collapse navbar-collapse pull-right ${
              headerColor === "transparent" ? "" : "navbar-collapses"
            }`}
            id="navbar-collapse-1"
          >
            <ul className="nav navbar-nav nav-pos-center navbar-left">
              <li>
                <Link to={"/en-gh"} onClick={scrolltop}>
                  Home
                </Link>
              </li>
              <li>
                <Link to={"/en-gh/carforsale"} onClick={scrolltop}>
                  Cars for Sale
                </Link>
              </li>
              <li>
                <Link to={"/en-gh/carforrent"} onClick={scrolltop}>
                  Cars for Rent
                </Link>
              </li>
            </ul>
            <div className="module module-property pull-left">
              {token ? (
                <a target="_blank" className="btn" onClick={HandleLogout}>
                  Logout
                </a>
              ) : (
                <a
                  ref={isLogin}
                  data-toggle="modal"
                  data-target="#signupModule"
                  className="btn"
                  onClick={() => setCondition({ ...condition, login: true })}
                >
                  <i className="fa fa-plus" /> Login
                </a>
              )}
              <Modal
                id={"signupModule"}
                setcondition={setCondition}
                condition={condition}
              />
            </div>
            {token && (
              <div
                className="module module-property pull-left"
                style={{ marginLeft: "10px" }}
              >
                <Link to="/en-gh/profile" className="btn">
                  <i className="fa fa-user" /> View Profile
                </Link>
              </div>
            )}
          </div>
        </div>
      </nav>
    </header>
  );
}

export default Header;

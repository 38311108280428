/* eslint-disable eqeqeq */
/* eslint-disable no-mixed-operators */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";
// import { toast } from "react-toastify";

function SocialSidebar() {
  const user = JSON.parse(localStorage.getItem("user"));
  const token = localStorage.getItem("Token");
  const is_verifiedUser = user?.is_verified == 1 && token
  return (
    <div className="col-xs-12 col-sm-12 col-md-4">
      <div className="edit--profile-area">
        <ul className="edit--profile-links list-unstyled mb-0">
          <li>
            <Link to={"/en-gh/profile"}>My Profile</Link>
          </li>
          {/* {user?.w_phone ? (
              <Link to={""} onClick={()=>toast.error("You Are Already Update Profile ")}>My Profile</Link>
            ) : (
            )}
          </li>
          {/* <li>
            {user?.is_verified !== 1 && token ? (
              <Link to={"/en-gh/verify"}>Verify Your Account</Link>
            ) : (
              ""
            )}
          </li> */}
          <li>
            {/* {user?.role === "host" && token ? (

<Link to={''}>My Properties</Link>
) :''} */}
          </li>
          <li>
            {is_verifiedUser && <Link to={"/en-gh/fav-car"}>Favorite Cars</Link>}
            {/* <a href="#">Favorite Properties</a> */}
          </li>
          {/* <li>
            <Link to={"/en-gh/change"}>Change Password</Link>
          </li> */}
          <li>

            {is_verifiedUser && (
              <Link to={"/en-gh/add_car_for_sale"}>Add Car for Sale</Link>
            )}
          </li>
          <li>

            {is_verifiedUser && (
              <Link to={"/en-gh/add_car_for_rent"}>Add Car for Rent</Link>
            )}
            </li>
          <li>
            {is_verifiedUser && <Link to={"/en-gh/mycarforsale"}>My Car for Sale</Link>}
          </li>
          <li>
            {is_verifiedUser && (
              <Link to={"/en-gh/mycarforrent"}>My Car for Rent</Link>
            )}
          </li>
          <li>
            {is_verifiedUser && (
              <Link to={"/en-gh/contact"}>Contact Us</Link>
            )}
          </li>
        </ul>
      </div>
    </div>
  );
}

export default SocialSidebar;

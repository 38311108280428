import React from "react";

function SimpleStep() {
  return (
    <section
      id="feature"
      className="feature feature-1 text-center bg-white pb-90"
    >
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-12">
            <div className="heading heading-2 text-center mb-70">
              <h2 className="heading--title">Three Simple Steps</h2>
              <p className="heading--desc">
                Discover millions of cars available for sale, rent, or lease.
              </p>
            </div>
            {/* .heading-title end */}
          </div>
          {/* .col-md-12 end */}
        </div>
        {/* .row end */}
        <div className="row">
          {/* feature Panel #1 */}
          <div className="col-xs-12 col-sm-4 col-md-4">
            <div className="feature-panel">
              <div className="feature--icon">
                <img src="assets/images/features/icons/5.png" alt="icon img" />
              </div>
              <div className="feature--content">
                <h3>Search for Your Next Car</h3>
                <p>
                  Easily browse our extensive selection of cars for sale or
                  rent.
                </p>
              </div>
            </div>
            {/* .feature-panel end */}
          </div>
          {/* .col-md-4 end */}
          {/* feature Panel #2 */}
          <div className="col-xs-12 col-sm-4 col-md-4">
            <div className="feature-panel">
              <div className="feature--icon">
                <img src="assets/images/features/icons/6.png" alt="icon img" />
              </div>
              <div className="feature--content">
                <h3>Select Your Favorite</h3>
                <p>
                  Contact the seller or rental agency via WhatsApp, call, or SMS
                  to schedule a test drive or viewing.{" "}
                </p>
              </div>
            </div>
            {/* .feature-panel end */}
          </div>
          {/* .col-md-4 end */}
          {/* feature Panel #3 */}
          <div className="col-xs-12 col-sm-4 col-md-4">
            <div className="feature-panel">
              <div className="feature--icon">
                <img src="assets/images/features/icons/77.png" alt="icon img" />
              </div>
              <div className="feature--content">
                <h3>Drive Away Happy</h3>
                <p>
                  Love the car? Finalize the deal and drive away in your new
                  ride!
                </p>
              </div>
            </div>
            {/* .feature-panel end */}
          </div>
          {/* .col-md-4 end */}
        </div>
        {/* .row end */}
      </div>
      {/* .container end */}
    </section>
  );
}

export default SimpleStep;

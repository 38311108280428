/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-mixed-operators */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
// import { axiosData } from "../../Config/Axios";
import { axiosInstance } from "../../Config/AxiosInstance";
import { useNavigate } from "react-router-dom";

function AboutAgent({ data, id }) {
  const token = localStorage.getItem("Token");
  const user = JSON.parse(localStorage.getItem("user"));
  const navigate = useNavigate();
  const GetYear = (e) => {
    e.preventDefault();
    axiosInstance
      .get(`/car-user?user_id=${id}`)
      .then((response) => {
        if (response?.data) {
          navigate("/en-gh/car", {
            state: response?.data?.data,
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  return (
    <div className="widget widget-property-agent">
      <div className="widget--title">
        <div style={{ display: "flex" }}>
          <h5 style={{ marginTop: "16px", width: "200px" }}>
            Listed by:{data?.host?.first_name}
          </h5>
        </div>
      </div>
      <div className="widget--content1">
        <a href="#">
          <div className="agent--img">
            <img
              src={data?.host?.image}
              alt="agent"
              className="img-responsive"
            />
            <img
              src="/assets/watermark.png"
              style={{
                position: "absolute",
                width: "250px",
                // inset:'0px 0px 20px 0px',
                opacity: 0.7,
                fontSize: "24px",
                // marginLeft: "33px",
                marginTop: "-50%",
                color: "white",
                textShadow: "1px 1px 2px black",
              }}
            />
            <img
              src="/assets/watermark.png"
              style={{
                position: "absolute",
                width: "250px",
                // inset:'0px 0px 20px 0px',
                opacity: 0.7,
                fontSize: "24px",
                // marginLeft: "33px",
                marginTop: "-40%",
                color: "white",
                textShadow: "1px 1px 2px black",
              }}
            />
            <img
              src="/assets/watermark.png"
              style={{
                position: "absolute",
                width: "250px",
                // inset:'0px 0px 20px 0px',
                opacity: 0.7,
                fontSize: "24px",
                // marginLeft: "33px",
                marginTop: "-30%",
                color: "white",
                textShadow: "1px 1px 2px black",
              }}
            />
          </div>
          {/* <div className="agent--info">
            <h5 className="agent--title">{data?.host?.first_name}</h5>
          </div> */}
        </a>
        <div className="agent--contact">
          <ul className="list-unstyled1">
            <li>
              {user?.is_verified == "1" && token ? (
                <a
                  className="btn btn--primary btn-file "
                  style={{ width: "200px" }}
                  href={`tel:+${data?.host?.phone}`}
                >
                  <i className="fa fa-phone" style={{ color: "white" }} />
                  Phone Number
                </a>
              ) : (
                <a
                  className="btn btn--primary btn-file disabled"
                  style={{ width: "200px" }}
                >
                  <i className="fa fa-phone" style={{ color: "white" }} />
                  Phone Number
                </a>
              )}
            </li>
            <li>
              {user?.is_verified == "1" && token ? (
                <a
                  className="btn btn--primary btn-file "
                  style={{ width: "200px" }}
                  href={`mailto:${data?.host?.email}`}
                >
                  <i className="fa fa-envelope" style={{ color: "white" }} />
                  Message
                </a>
              ) : (
                <a
                  className="btn btn--primary btn-file disabled "
                  style={{ width: "200px" }}
                  href="a"
                >
                  <i className="fa fa-envelope" style={{ color: "white" }} />
                  Message
                </a>
              )}
            </li>
            <li>
              {user?.is_verified == "1" && token ? (
                <a
                  className="btn btn--primaryw btn-file "
                  style={{ width: "200px" }}
                  href={`https://wa.me/${data?.host?.w_phone}`}
                >
                  <i className="fa fa-whatsapp" style={{ color: "white" }} />
                  whatsapp
                </a>
              ) : (
                <a
                  className="btn btn--primaryw btn-file disabled"
                  style={{ width: "200px" }}
                >
                  whatsapp
                </a>
              )}
            </li>
            <li>
              {user?.is_verified == "1" && token ? (
                <a
                  className="btn btn--primaryw colornothover btn-file "
                  style={{ width: "200px" }}
                  onClick={(e) => GetYear(e)}
                >
                  <i className="fa fa-eye" style={{ color: "white" }} />
                  View All Cars
                </a>
              ) : (
                // <a
                //   className="btn btn--primaryw btn-file disabled"
                //   style={{ width: "200px" }}
                // >
                //   <i className="fa fa-eye" style={{ color: "white" }} />
                //   View Car
                // </a>
                <a
                className="btn btn--primaryw colornothover btn-file "
                style={{ width: "200px" }}
                onClick={(e) => GetYear(e)}
              >
                <i className="fa fa-eye" style={{ color: "white" }} />
                View All Cars
              </a>
              )}
            </li>
          </ul>
        </div>
        {/* .agent-contact end */}
        {/* <div className="agent--social-links">
          {user?.is_verified === '1'  || user?.is_verified === 1  && token ? (
            <a href={`tel:+${data?.host?.phone}`}>
              <i className="fa fa-phone" />
            </a>
          ) : (
            <a href="link.html" className="disabled">
              <i className="fa fa-phone" />
            </a>
          )}
          {user?.is_verified === '1'  || user?.is_verified === 1  && token ? (
            <a href={`mailto:${data?.host?.email}`} target="_blank">
              <i className="fa fa-envelope-o" />
            </a>
          ) : (
            <a href="link.html" className="disabled">
              <i className="fa fa-envelope-o" />
            </a>
          )}
          {user?.is_verified === '1'  || user?.is_verified === 1  && token ? (
            <a
              href={`https://wa.me/${data?.host?.w_phone}`}
              target="_blank"
              rel="hello"
            >
              <i className="fa fa-whatsapp" />
            </a>
          ) : (
            <a href="link.html" className="disabled">
              <i className="fa fa-whatsapp" />
            </a>
          )}
          <a href="#">
            <i className="fa fa-linkedin" />
          </a>
        </div> */}
        {/* .agent-social-links */}
      </div>
    </div>
  );
}

export default AboutAgent;

import React from 'react'
import Layout from '../../Component/Layout/Layout'
import AddcarForRentCom from './AddcarForRentCom'

export default function AddcarForRent() {
  return (
    <Layout>
    <AddcarForRentCom />
  </Layout>
  )
}

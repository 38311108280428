/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState, useRef } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// Import required modules
import { Pagination } from "swiper/modules";
import GalleryLoader from "../Loader/GalleryLoader";

function GalleryPicture({ data, loader }) {
  const listingImages = data?.car_images || []; // Default to an empty array if not available

  const NewData =
    listingImages.length >= 5 && data?.is_link_publish === 0
      ? [
          ...listingImages.slice(0, 5),
          {
            id: null,
            image:
              "https://www.firstbenefits.org/wp-content/uploads/2017/10/placeholder.png",
          },
        ]
      : [...listingImages];
      // console.log(NewData.l)

  const [activeIndex, setActiveIndex] = useState(0);

  const swiperRef = useRef(null);

  const goNext = () => {
    if (swiperRef.current) {
      swiperRef.current.swiper.slideNext();
    }
  };

  const goPrev = () => {
    if (swiperRef.current) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const handleThumbnailClick = (index) => {
    if (swiperRef.current) {
      swiperRef.current.swiper.slideTo(index);
    }
    setActiveIndex(index);
  };

  const token = localStorage.getItem("Token");
  const user = JSON.parse(localStorage.getItem("user"));

  return (
    <div className="col-xs-12 col-sm-12 col-md-12">
      {loader ? (
        <GalleryLoader />
      ) : (
        <div className="property-single-carousel-content">
          <div className="carousel carousel-thumbs slider-navs">
            <Swiper
              spaceBetween={30}
              pagination={{ clickable: true }}
              navigation={{
                prevEl: ".owl-prev",
                nextEl: ".owl-next",
              }}
              ref={swiperRef}
              modules={[Pagination]}
              className="mySwiper"
              onSlideChange={(swiper) => setActiveIndex(swiper.activeIndex)}
            >
              {data?.car_images?.slice(0, 5)?.map((e, index) => (
                <SwiperSlide key={index}>
                  <img
                  className="w_100h_388"
                    // style={{ width: "100%", height: "auto" }}
                    src={e?.image}
                    alt="Property Image"
                  />
                  <div>
                    <img
                      src="/assets/watermark.png"
                      style={{
                        position: "absolute",
                        bottom: "23px",
                        width: "88%",
                        opacity: 0.4,
                        fontSize: "24px",
                        marginLeft: "33px",
                        color: "white",
                        textShadow: "1px 1px 2px black",
                      }}
                    />
                  </div>
                  <div
                    style={{
                      position: "relative",
                      bottom: "100px",
                      display: "flex",
                      justifyContent: "space-evenly",
                    }}
                  >
                    <button className="bg-black p-xs border-none button-detail">
                      {user?.is_verified === "1" ||
                      (user?.is_verified === 1 && token) ? (
                        <a
                          href={`tel:+${data?.host?.phone}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i
                            className="fa fa-phone"
                            style={{ color: "white" }}
                          />
                        </a>
                      ) : (
                        <a
                          href={``}
                          onClick={(e) => {
                            e?.preventDefault();
                            alert("YOU MUST BE VERIFIED TO USE THIS FEATURE.");
                          }}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i
                            className="fa fa-phone"
                            style={{ color: "white" }}
                          />
                        </a>
                      )}
                    </button>
                    <button className="btn--primaryw p-xs ml-xs border-none button-detail">
                      {user?.is_verified === "1" ||
                      (user?.is_verified === 1 && token) ? (
                        <a
                          href={`https://wa.me/${data?.host?.w_phone}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i
                            className="fa fa-whatsapp"
                            style={{ color: "white" }}
                          />
                        </a>
                      ) : (
                        <a
                          href={``}
                          onClick={(e) => {
                            e?.preventDefault();
                            alert("YOU MUST BE VERIFIED TO USE THIS FEATURE.");
                          }}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i
                            className="fa fa-whatsapp"
                            style={{ color: "white" }}
                          />
                        </a>
                      )}
                    </button>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
            <div className="owl-controls">
              <div className="owl-nav">
                <div
                  className="owl-prev"
                  style={{ zIndex: "2" }}
                  onClick={goPrev}
                >
                  prev
                </div>
                <div
                  className="owl-next"
                  onClick={goNext}
                  style={{ zIndex: "2" }}
                >
                  next
                </div>
              </div>
              <div className="owl-dots" style={{ display: "none" }} />
            </div>
          </div>
          {
            NewData?.length===6 ?(

          <div
            className="owl-thumbs thumbs-bg"
            style={{ display: "flex", justifyContent: "space-between" }}
            data-slider-id={1}
          >
            {NewData?.map((e, index) => (
              <button
                key={index}
                className={`owl-thumb-item ${
                  activeIndex === index ? "active" : ""
                }`}
                onClick={() => handleThumbnailClick(index)}
              >
                <img
                className="w-130_laptoph-auto"
                  // style={{ width: "130px", height: "auto" }}
                  src={e?.image}
                  alt="Property Image thumb"
                />
              </button>
            ))}
          </div>
            )
            :       <div
            className="owl-thumbs thumbs-bg"
            style={{ display: "flex", justifyContent: "space-between" }}
            data-slider-id={1}
          >
            {NewData?.slice(0,5)?.map((e, index) => (
              <button
                key={index}
                className={`owl-thumb-item ${
                  activeIndex === index ? "active" : ""
                }`}
                onClick={() => handleThumbnailClick(index)}
              >
                <img
                className="w-130_laptoph-auto"
                  // style={{ width: "130px", height: "auto" }}
                  src={e?.image}
                  alt="Property Image thumb"
                />
              </button>
            ))}
          </div>
          }
          {/* .carousel end */}
        </div>
      )}
    </div>
  );
}

export default GalleryPicture;

import React, { useEffect, useState } from "react";
import { Rating } from "react-simple-star-rating";
import { GetAllReview } from "../../Helper/ReviewServices";

function AllReview({ id, Data }) {
  const [data, setData] = useState([]);
  const [, setloader] = useState(false);

  useEffect(() => {
    GetAllReview(setloader,setData,id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="property-single-reviews inner-box">
      <div className="row">
        <div
          className="col-xs-12 col-sm-12 col-md-12"
          style={{ padding: "10px" }}
        >
          <div className="heading">
            <h2 className="heading--title">
              {data?.length + " "} Reviews{" "}
              <Rating
                initialValue={Number(Data?.total_star)}
                allowFraction={true}
                fillColor="black"
                disableFillHover
                size={20}
              />{" "}
              {Data?.total_star}/ 5.0
            </h2>
          </div>
        </div>
        {/* .col-md-12 end */}
        <div className="col-xs-12 col-sm-12 col-md-12">
          <ul className="property-review">
            {data?.map((e) => {
              return (
                <li className="review-comment">
                  <img
                    className="avatar"
                    src={
                      e?.image
                        ? e?.image
                        : "https://www.londondentalsmiles.co.uk/wp-content/uploads/2017/06/person-dummy.jpg"
                    }
                    alt="UserImage"
                  />
                  {/* <div className="avatar">R</div> */}
                  <div className="comment">
                    <h6>{e?.user?.first_name + " " + e?.user?.last_name}</h6>
                    <div className="date">{e?.date}</div>
                    <div className="property-rating">
                      <Rating
                        initialValue={e?.star}
                        allowFraction={true}
                        fillColor="black"
                        size={20}
                      />{" "}
                      <span
                        style={{
                          position: "relative",
                          inset: "-3px 0px 0px 0px",
                        }}
                      >
                        {e?.star}/ 5.0
                      </span>
                    </div>
                    <p>{e?.comment}</p>
                  </div>
                </li>
              );
            })}
          </ul>
          {/* .comments-list end */}
        </div>
        {/* .col-md-12 end */}
      </div>
      {/* .row end */}
    </div>
  );
}

export default AllReview;

import React, { useState, useEffect, useContext } from "react";
import "../Header.css";
// import axios from "axios";
import Room from "../Type/RoomCom";

// import { GetDataone } from "../Aminittes";
import { AuthContext } from "../../Config/Context/Context";

// const geonamesUsername = "sywyj";

const HeaderRoom = () => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [makes] = useState([
    { value: "toyota", label: "Toyota" },
    { value: "honda", label: "Honda" },
    { value: "ford", label: "Ford" },
    { value: "bmw", label: "BMW" },
    { value: "mercedes", label: "Mercedes" },
    // Add more makes as needed
  ]);
  const [models, setModels] = useState([]); // Models will be populated based on selected make
  let { filters, setFilters } = useContext(AuthContext);

  const [filteredData, setFilteredData] = useState([]);
  const [, setType] = useState([]);

  const toggleDropdownVisibility = () => {
    setDropdownVisible(!dropdownVisible);
  };

  useEffect(() => {
    // Static data for types if needed
    setType([{ name: "Sedan" }, { name: "SUV" }, { name: "Truck" }]);
  }, []);

  const dropdownOptions = {
    years: [
      { label: "2010", value: "2010" },
      { label: "2011", value: "2011" },
      { label: "2012", value: "2012" },
      { label: "2013", value: "2013" },
      { label: "2014", value: "2014" },
      // Add more years as needed
    ],
    transmissions: [
      { label: "Automatic", value: "automatic" },
      { label: "Manual", value: "manual" },
    ],
    price: [
      { label: "Below GH₵ 60,000", value: "below_60000" },
      { label: "GH₵ 60,000 - 100,000", value: "60000_to_100000" },
      { label: "GH₵ 100,000 - 250,000", value: "100000_to_250000" },
      { label: "Above GH₵ 250,000", value: "above_250000" },
    ],
    sorts: [
      { label: "Newest-to-oldest", value: "desc" },
      { label: "Oldest-to-newest", value: "asc" },
      { label: "Price - highest-to-lowest", value: "price_desc" },
      { label: "Price - lowest-to-highest", value: "price_asc" },
    ],
    fuelType: [
      { label: "Petrol", value: "petrol" },
      { label: "Diesel", value: "diesel" },
    ],
    engineSize: [
      { label: "Below 1.0L", value: "below_1.0" },
      { label: "1.0L - 2.0L", value: "1.0_to_2.0" },
      { label: "Above 2.0L", value: "above_2.0" },
    ],
    doors: [
      { label: "2", value: "2" },
      { label: "4", value: "4" },
      { label: "5", value: "5" },
      { label: "More than 5", value: "more_than_5" },
    ],
  };

  const reset = () => {
    setFilters({
      selectedMake: "",
      selectedModel: "",
      cityTown: "",
      years: "",
      transmissions: "",
      price: "",
      sorts: "",
      fuelType: "",
      engineSize: "",
      doors: "",
    });
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));

    // Set models based on the selected make
    if (name === "selectedMake") {
      setModels(getModelsByMake(value));
    }
  };

  const getModelsByMake = (make) => {
    const modelsByMake = {
      toyota: [
        { value: "corolla", label: "Corolla" },
        { value: "camry", label: "Camry" },
      ],
      honda: [
        { value: "civic", label: "Civic" },
        { value: "accord", label: "Accord" },
      ],
      ford: [
        { value: "fiesta", label: "Fiesta" },
        { value: "focus", label: "Focus" },
      ],
      bmw: [
        { value: "x3", label: "X3" },
        { value: "x5", label: "X5" },
      ],
      mercedes: [
        { value: "c_class", label: "C-Class" },
        { value: "e_class", label: "E-Class" },
      ],
    };
    return modelsByMake[make] || [];
  };

  // eslint-disable-next-line no-unused-vars
  const applyFilters = () => {
    setFilteredData(filters);
  };

  const getFilterCount = () => {
    return Object.values(filters).filter((value) => value).length;
  };

  useEffect(() => {
    const handleResize = () => {
      setDropdownVisible(window.innerWidth >= 768);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <div className="header1">
        <div className="flex button_filter">
          <button className="toggle-btnf" onClick={toggleDropdownVisibility}>
            <i className="fa fa-server" style={{ fontSize: "14px", marginRight: "10px" }}></i>
            Filters <span className="filter-count">{getFilterCount()}</span>
          </button>

          <button type="submit" className="btn btn--primary btn-filter2" onClick={reset}>
            Reset
          </button>
        </div>

        <div className={`${dropdownVisible ? "show dropdowns" : "d-none"}`}>
          <div className="dropdown">
            <select
              className="filter-btn"
              name="selectedMake"
              value={filters.selectedMake}
              onChange={handleFilterChange}
            >
              <option value="">Make</option>
              {makes.map((option, i) => (
                <option key={i} value={option.value}>{option.label}</option>
              ))}
            </select>
          </div>
          <div className="dropdown">
            <select
              className="filter-btn"
              name="selectedModel"
              value={filters.selectedModel}
              onChange={handleFilterChange}
            >
              <option value="">Model</option>
              {models.map((option, i) => (
                <option key={i} value={option.value}>{option.label}</option>
              ))}
            </select>
          </div>
          <div className="dropdown">
            <input
              type="text"
              placeholder="City/Town"
              name="cityTown"
              className="filter-input"
              value={filters.cityTown}
              onChange={handleFilterChange}
            />
          </div>
          <div className="dropdown">
            <select
              className="filter-btn"
              name="years"
              value={filters.years}
              onChange={handleFilterChange}
            >
              <option value="">Years</option>
              {dropdownOptions.years.map((option, i) => (
                <option key={i} value={option.value}>{option.label}</option>
              ))}
            </select>
          </div>
          <div className="dropdown">
            <select
              className="filter-btn"
              name="transmissions"
              value={filters.transmissions}
              onChange={handleFilterChange}
            >
              <option value="">Transmission</option>
              {dropdownOptions.transmissions.map((option, i) => (
                <option key={i} value={option.value}>{option.label}</option>
              ))}
            </select>
          </div>
          <div className="dropdown">
            <select
              className="filter-btn"
              name="price"
              value={filters.price}
              onChange={handleFilterChange}
            >
              <option value="">Price</option>
              {dropdownOptions.price.map((option, i) => (
                <option key={i} value={option.value}>{option.label}</option>
              ))}
            </select>
          </div>
          <div className="dropdown">
            <select
              className="filter-btn"
              name="sorts"
              value={filters.sorts}
              onChange={handleFilterChange}
            >
              <option value="">Sort</option>
              {dropdownOptions.sorts.map((option, i) => (
                <option key={i} value={option.value}>{option.label}</option>
              ))}
            </select>
          </div>
          <div className="dropdown">
            <select
              className="filter-btn"
              name="fuelType"
              value={filters.fuelType}
              onChange={handleFilterChange}
            >
              <option value="">Fuel Type</option>
              {dropdownOptions.fuelType.map((option, i) => (
                <option key={i} value={option.value}>{option.label}</option>
              ))}
            </select>
          </div>
          <div className="dropdown">
            <select
              className="filter-btn"
              name="engineSize"
              value={filters.engineSize}
              onChange={handleFilterChange}
            >
              <option value="">Engine Size</option>
              {dropdownOptions.engineSize.map((option, i) => (
                <option key={i} value={option.value}>{option.label}</option>
              ))}
            </select>
          </div>
          <div className="dropdown">
            <select
              className="filter-btn"
              name="doors"
              value={filters.doors}
              onChange={handleFilterChange}
            >
              <option value="">No. of Doors</option>
              {dropdownOptions.doors.map((option, i) => (
                <option key={i} value={option.value}>{option.label}</option>
              ))}
            </select>
          </div>
          <button type="submit" className="btn btn--primary btn-filter1" onClick={reset}>
            Reset
          </button>
        </div>
      </div>

      <Room filters={filteredData}  />
    </>
  );
};

export default HeaderRoom;

import React, { useState, useEffect, useContext } from "react";
import "../Header.css";
// import axios from "axios";

// import { GetDataone } from "../Aminittes";
import HouseCom from "../Type/HouseCom.";
// import { AuthContext } from "../../Config/Context/Context";
// import HouseCom from "../Type/HouseCom";
import { AuthContext } from "../../Config/Context/Context";
import {
  dropdownOptions,
  GetMake,
  Getmodel,
  GetTrim,
  Year,
  // years,
} from "../../Helper/PropertyService";

// const geonamesUsername = "sywyj";

const HeaderHouse = () => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [, setloader] = useState(true);
  const [makes, setmakes] = useState([]);
  let { filtersRent, setFiltersRent } = useContext(AuthContext);
  const [models, setModels] = useState([]);
  const [years, setyear] = useState([]);
  // const [variant, setvariants] = useState([]);
  useEffect(() => {
    GetMake(setloader, setmakes);
    if (filtersRent?.selectedMake) {
      Getmodel(filtersRent?.selectedMake, setloader, setModels);
    }
    Year(setyear)

    if (filtersRent?.selectedvariant) {
      GetTrim(
        filtersRent?.selectedvariant,
        setloader,
        // settirm,
        filtersRent?.selectedMake,
        filtersRent?.selectedModel
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersRent?.selectedModel, filtersRent?.selectedMake, filtersRent?.selectedtrim]);

  const [filteredData, setFilteredData] = useState([]);
  const [, setType] = useState([]);

  const toggleDropdownVisibility = () => {
    setDropdownVisible(!dropdownVisible);
  };

  useEffect(() => {
    setType([{ name: "Sedan" }, { name: "SUV" }, { name: "Truck" }]);
  }, []);

  const reset = () => {
    setFiltersRent({
      selectedMake: "",
      selectedModel: "",
      cityTown: "",
      years: "",
      transmissions: "",
      price_per_day: "",
      sorts: "",
      selectedvariant: "",
      fuelType: "",
      selectedtrim: "",
      engineSize: "",
      doors: "",
    });
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFiltersRent((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  // eslint-disable-next-line no-unused-vars
  const applyFilters = () => {
    setFilteredData(filtersRent);
  };

  const getFilterCount = () => {
    return Object.values(filtersRent).filter((value) => value).length;
  };

  useEffect(() => {
    const handleResize = () => {
      setDropdownVisible(window.innerWidth >= 768);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
        <div className="header1">
        <div className="flex button_filter">
          <button className="toggle-btnf" onClick={toggleDropdownVisibility}>
            <i
              className="fa fa-server"
              style={{ fontSize: "14px", marginRight: "10px" }}
            ></i>
            Filters <span className="filter-count">{getFilterCount()}</span>
          </button>

          <button
            type="submit"
            className="btn btn--primary btn-filter2"
            onClick={reset}
          >
            Reset
          </button>
        </div>

        <div className={`${dropdownVisible ? "show dropdowns" : "d-none"}`}>
          <div className="dropdown">
            <select
              className="filter-btn"
              name="selectedMake"
              value={filtersRent.selectedMake}
              onChange={handleFilterChange}
            >
              <option value="">Make</option>
              {makes.map((option, i) => (
                <option key={i} value={option.id}>
                  {option.name}
                </option>
              ))}
            </select>
          </div>
          <div className="dropdown">
            <select
              className="filter-btn"
              name="selectedModel"
              value={filtersRent.selectedModel}
              onChange={handleFilterChange}
            >
              <option value="">Model</option>
              {models.map((option, i) => (
                <option key={i} value={option.id}>
                  {option.name}
                </option>
              ))}
            </select>
          </div>

          <div className="dropdown">
            <input
              type="text"
              placeholder="Trim"
              name="selectedtrim"
              className="filter-input"
              value={filtersRent.selectedtrim}
              onChange={handleFilterChange}
            />
          </div>
          <div className="dropdown">
            <input
              type="text"
              placeholder="CIty/Town"
              name="cityTown"
              className="filter-input"
              value={filtersRent.cityTown}
              onChange={handleFilterChange}
            />
          </div>
          <div className="dropdown">
            <select
              className="filter-btn"
              name="years"
              value={filtersRent.years}
              onChange={handleFilterChange}
            >
              <option value="">Years</option>
              {years.map((option, i) => (
                <option key={i} value={option.id}>
                  {option.name}
                </option>
              ))}
            </select>
          </div>
          <div className="dropdown">
            <select
              className="filter-btn"
              name="transmissions"
              value={filtersRent.transmissions}
              onChange={handleFilterChange}
            >
              <option value="">Transmission</option>
              {dropdownOptions.transmissions.map((option, i) => (
                <option key={i} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
          <div className="dropdown">
            <select
              className="filter-btn"
              name="price_per_day"
              value={filtersRent.price_per_day}
              onChange={handleFilterChange}
            >
              <option value="">Price Rent</option>
              {dropdownOptions.price_per_day.map((option, i) => (
                <option key={i} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
          <div className="dropdown">
            <select
              className="filter-btn"
              name="sorts"
              value={filtersRent.sorts}
              onChange={handleFilterChange}
            >
              <option value="">Sort</option>
              {dropdownOptions.sorts.map((option, i) => (
                <option key={i} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
          <div className="dropdown">
            <select
              className="filter-btn"
              name="fuelType"
              value={filtersRent.fuelType}
              onChange={handleFilterChange}
            >
              <option value="">Fuel Type</option>
              {dropdownOptions.fuelType.map((option, i) => (
                <option key={i} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
          <div className="dropdown">
            <select
              className="filter-btn"
              name="engineSize"
              value={filtersRent.engineSize}
              onChange={handleFilterChange}
            >
              <option value="">Engine Size</option>
              {dropdownOptions.engineSize.map((option, i) => (
                <option key={i} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
          <div className="dropdown">
            <select
              className="filter-btn"
              name="doors"
              value={filtersRent.doors}
              onChange={handleFilterChange}
            >
              <option value="">No. of Doors</option>
              {dropdownOptions.doors.map((option, i) => (
                <option key={i} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
          <button
            type="submit"
            className="btn btn--primary btn-filter1"
            onClick={reset}
          >
            Reset
          </button>
        </div>
      </div>
      <HouseCom filters={filteredData}  />
    </>
  );
};

export default HeaderHouse;

/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable */
import React, { useEffect, useState } from "react";
// import Map from "./Map.js";
// import HeaderHello from "./HeaderHello.js";
import { Link, useLocation, useNavigate } from "react-router-dom";
// import { axiosData } from "../Config/Axios.js";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { axiosInstancenew } from "../../Config/AxiosNew.js";
import { axiosData } from "../../Config/Axios.js";
import { toast } from "react-toastify";
import { WhatsappFun } from "../../Helper/CallingServices.js";
import { HandleFav } from "../../Helper/AddToFavServices.js";
import LoaderCard from "../Loader/LoaderCard.js";
// import { axiosInstancenew } from "../Config/AxiosNew.js";
function Feature({ image }) {
  const { state } = useLocation();
  const [headerColor, setHeaderColor] = useState("transparent");
  const user = JSON.parse(localStorage.getItem("user"));
  const token = localStorage.getItem("Token");
  const [grid, setgrid] = useState("");

  const [data, setData] = useState([]);
  const [loader, setloader] = useState(false);
  const [isFavourite, setIsFavourite] = useState(data?.isFavourite === 1);
  const navigate = useNavigate();
  const GetData = () => {
    setloader(true);
    axiosInstancenew
      .get(`/car-user`)
      .then((res) => {
        if (res?.data?.status === true) {
          const featuredProperties = res?.data?.data?.filter(
            (item) => item.is_featured === 1
          );

          setData(featuredProperties);
          // console.log(featuredProperties, "featuredProperties");
          setloader(false);
        }
        // console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
        setloader(true);
      });
  };
  // const HandleFav = (id) => {
  //   const FormDat = new FormData();
  //   FormDat.append("car_id", id);
  //   axiosData
  //     .post(`/add-to-favourite`, FormDat)
  //     .then((res) => {
  //       console.log(res.data);
  //       if (res?.data?.message === "Unsave") {
  //         setIsFavourite(false);
  //         toast.success("Unfavorite");
  //         GetData();
  //       } else {
  //         toast.success("favorite");
  //         setIsFavourite(true);
  //         GetData();
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  useEffect(() => {
    GetData();

    window.scrollTo(0, 0);
    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      if (currentScrollY < 1700) {
        setHeaderColor("blue");
      } else {
        setHeaderColor("transparent");
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []); //
  return (
    <>
      <div
        className="properties-split pt-0 pb-0 bg-white"
        id="properties-split"
      >
        <div className="container-fluid">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12">
              {/* <p className="heading--desc text-center">
                Discover millions of rooms, houses, and apartments for rent.
              </p> */}
              <div className="heading heading-2 text-center mt-20">
                <h2 className="heading--title">Featured Cars For Sale And Rent</h2>
                {/* <a className="heading--desc text-center" href="#">
                  <Link to={"/feature"}>View all featured rental listings</Link>
                </a> */}
              </div>
              {/* .heading-title end */}
            </div>
            {/* .col-md-12 end */}
          </div>
          <div className="">
            <div className="col-xs-12 col-sm-12 col-md-12 responsiveCard">
              <div className="row  pt-45 properties-list listgrid">
                <div className="col-xs-12 col-sm-12 col-md-12">
                  <div className="properties-filter clearfix">
                    {/* <div className="select--box pull-left">
                      <label>{data?.length} Results Found</label>
                    </div> */}
                    {/* <div className="view--type pull-right">
                      <a
                        className={grid === "list" ? "active" : ""}
                        href="#"
                        id="switch-list"
                        onClick={() => setgrid("list")}
                      >
                        <i className="fa fa-th-list" />
                      </a>
                      <a
                        className={grid === "grid" ? "active" : ""}
                        href="#"
                        id="switch-grid"
                        onClick={() => setgrid("grid")}
                      >
                        <i className="fa fa-th-large" />
                      </a>
                    </div> */}
                  </div>
                </div>

                <div
                  className={`properties properties-grid ${
                    grid === "list" ? "newhello1" : "newhello"
                  }`}
                >
                  {loader ? (
                    <LoaderCard />
                  ) : (
                    data.map((e) => (
                      <div key={e.id} className="col-xs-12 col-sm-12 col-md-12">
                        <div
                          className={`property-item ${
                            grid === "list" ? "listby" : ""
                          }`}
                        >
                          <div className="property--img">
                            {loader ? (
                              <Skeleton style={{ height: "200px" }} />
                            ) : (
                              <img
                                alt="property image"
                                onClick={() =>
                                  navigate(`/en-gh/singlecar/${e.id}`)
                                }
                                className={`img-responsive ${
                                  grid === "list" ? "h-274" : "h-200"
                                }`}
                                src={e.car_images?.[0]?.image}
                              />
                            )}
                            {/* </a> */}
                            <span className={e?.carstype ==='sale' ? 'property--status':'property--statusred'}>
                              {loader && <Skeleton />}
                              {e?.carstype === "sale" ? "For Sale" : "For Rent"}
                            </span>
                          </div>
                          <div className="property--content">
                            <div className="property--info">
                              <h5 className="property--title">
                                {loader ? (
                                  <Skeleton count={3} />
                                ) : (
                                  <>
                                    <a href="#">{`${e?.year} ${e?.make?.name} ${e?.model?.name} ${e?.trim}`}</a>
                                    <span
                                      className="value"
                                      onClick={() =>
                                        HandleFav(e?.id, user, GetData)
                                      }
                                      style={{ float: "right" }}
                                    >
                                      {" "}
                                      {e?.is_favourite == 1 ? (
                                        <i
                                          className="fa fa-heart"
                                          style={{
                                            marginLeft: "10px",
                                            cursor: "pointer",
                                            color: "#0099cc ",
                                          }}
                                        />
                                      ) : (
                                        <i
                                          className="fa fa-heart-o"
                                          style={{
                                            marginLeft: "10px",
                                            cursor: "pointer",
                                          }}
                                        />
                                      )}
                                    </span>
                                  </>
                                )}
                              </h5>
                              <p className="property--location">
                                <i className="fa fa-map-marker" />
                                {loader ? (
                                  <Skeleton />
                                ) : (
                                  // `${e?.location}`
                                  `${e?.city}, ${e?.district}, ${e?.region}`
                                )}
                                <span style={{ float: "right" }}>
                                  {e?.created_at}
                                </span>
                              </p>
                              <div className="flex1">
                                {e?.carstype === "rent" ? (
                                  <p
                                    className="property--price "
                                    style={{ display: "flex" }}
                                  >
                                    {loader && <Skeleton />}
                                    GH₵{e?.price_per_day}
                                    <sup>/Day</sup>
                                  </p>
                                ) : (
                                  <p className="property--price">
                                    {loader ? <Skeleton /> : `GH₵` + e?.price}{" "}
                                    {/* <sup>/month</sup> */}
                                  </p>
                                )}
                                <div style={{ display: "flex" }}>
                                  <button className="bg-black p-xs border-none button-detail">
                                    {user?.is_verified === 1 ||
                                    (user?.is_verified === "1" && token) ? (
                                      <a
                                        href={`tel:+${e?.host?.phone}`}
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        <i
                                          className="fa fa-phone"
                                          style={{ color: "white" }}
                                        />
                                      </a>
                                    ) : (
                                      <a
                                        href={``}
                                        onClick={(e) => {
                                          e?.preventDefault();
                                          alert(
                                            "YOU MUST BE VERIFIED TO USE THIS FEATURE. "
                                          );
                                        }}
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        <i
                                          className="fa fa-phone"
                                          style={{ color: "white" }}
                                        />
                                      </a>
                                    )}
                                  </button>
                                  <button
                                    className="btn--primaryw  p-xs ml-xs border-none button-detail"
                                    onClick={() => WhatsappFun(e, user)}
                                  >
                                    <i
                                      className="fa fa-whatsapp"
                                      style={{ color: "white" }}
                                    />
                                  </button>
                                  {e?.host?.is_verified == "1" && (
                                    <img
                                      style={{
                                        width: "50px",
                                        marginTop: "-3px",
                                      }}
                                      src="/assets/images/verify.webp"
                                    />
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="property--features">
                              {/* {/* <p>{e?.description.slice(0, 83)}</p> */}
                              <div className="flex1">
                                <div className="">
                                  <img
                                    className="mtt-10"
                                    src="https://static-assets.cargurus.com/images/site-cars/vdp-icons/icon-total-mileage_40eabb5c87e87bc447dfc14f55bbd575bfdb10a978c61a1fb1fad87148f15721.svg"
                                    alt=""
                                  />
                                  <span className="ml-nine bold font-20 text-black ">
                                    {e?.mileage_type +' '+e?.mileage}
                                  </span>
                                </div>
                                <div className="">
                                  <img
                                    className="mtt-10"
                                    src="https://static-assets.cargurus.com/images/site-cars/vdp-icons/icon-transmission_6d95c32965c640395c6e92981701da6de13a781ab863ce0c190e8a84fb2e5754.svg"
                                    alt=""
                                  />
                                  <span className="ml-nine bold font-20 text-black ">
                                    {e?.transmission }
                                  </span>
                                </div>
                                <div className="">
                                  <img
                                    className="mtt-10"
                                    src="https://static-assets.cargurus.com/images/site-cars/vdp-icons/icon-engine_340b2adb88d16921896d3f1abbcea040c44f353cd3121297cbbb460336dcc3bf.svg"
                                    alt=""
                                  />
                                  <span className="ml-nine bold font-20 text-black ">
                                    {e?.engine_size?.name}
                                  </span>
                                </div>
                                {/* <div className="">
                               
                                  <img
                                    className="mtt-10"
                                    src="https://static-assets.cargurus.com/images/site-cars/vdp-icons/icon-fuel-type_670bab1e0b56aac2d6635a5cb353099061396d70dca9c904ed4d5cf16c704c58.svg"
                                    alt=""
                                  />
                                  <span className="ml-nine bold font-20 text-black ">
                                    {e?.fuel_type}
                                  </span>
                                </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="Banner-Image">
        <img src={image?.[0]?.image_one} alt="" />
      </div> */}
    </>
  );
}

export default Feature;
